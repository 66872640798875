let env;
if (process.env.REACT_APP_BUILD_MODE === "prod") {
  // For production build
  env = { ...process.env, ...window["env"] };
} else {
  // For development
  env = {
    BACKEND_URL: "http://localhost:3011/",
    custom: "lmrf-send",
    LOGO: "jm_logo.png",
    DEFAULT_SERVICE:
      "Maksātnespējas līguma sagatavošana ar garantiju par lietas ierosināšanu",
    DEFAULT_PRICE: 53.71,
    LEADS: true,
    EPAY: true,
    INVNAME: "JBMNJ1",
    LIMITED_TASKS: false,
    events: true,
    LIMITED_CLIENTS: false,
    SHOW_MARKETING: true,
    LEADLIST: [
      { value: "sungo", label: "SUNGO" },
      { value: "erti", label: "Erti.lv Matrači" },
      { value: "jbmnj", label: "JBMNJ" },
      { value: "majaelpo", label: "MajaElpo.lv" },
      { value: "lmrf", label: "LMRF.lv" },
      { value: "crm", label: "SOMECRM" },
      { value: "11", label: "Skaistuma studija" },
      { value: "7", label: "Auto Rīga" },
      { value: "12", label: "Skvoša skola" },
    ],
  };
}

export { env };
